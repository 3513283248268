<template>
  <b-overlay :show="loading">
      <b-row>
      <b-col lg="12" sm="12">
          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
            <b-row>
              <b-col sm="10">
                <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="5"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- <b-col sm="10">
                <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="5"
                      label-for="circular_memo_no"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.circular_memo_no"
                        :options="circularList"
                        id="circular_memo_no"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col> -->
              <b-col sm="10">
              <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="circular_memo_no"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                        id="circular_memo_no"
                        v-model="formData.circular_memo_no"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
              <!-- <b-col sm="10">
              <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="fiscal_year_id"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col> -->
              <b-col sm="10">
              <ValidationProvider name="Nominated Organization Type"  vid="org_type_id">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="org_type_id"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('elearning_iabm.nominated_organization_type')}}
                    </template>
                    <v-select
                      id="org_type_id"
                      v-model="formData.org_type_id"
                      @input="getOrgList(formData.org_type_id)"
                      multiple
                      :reduce="op => op.value"
                      :options="orgTypeList"
                      label="text"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                    </v-select>
                    <div class="invalid-feedback d-block">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
              <b-col sm="10">
              <ValidationProvider name="Nominated Organization"  vid="nominated_org_id" v-if="formData.org_type_id !== null">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="nominated_org_id"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('elearning_iabm.nominated_organization')}}
                    </template>
                    <v-select
                      id="nominated_org_id"
                      v-model="formData.nominated_org_id"
                      multiple
                      :reduce="op => op.value"
                      :options="orgList"
                      label="text"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                    </v-select>
                    <div class="invalid-feedback d-block">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
              <b-col sm="10">
              <ValidationProvider name="Training Provider Organization"  vid="org_id"  rules="required|min_value:1">
                <b-form-group
                  class="row"
                  label-cols-sm="5"
                  label-for="org_id"
                  slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                  {{$t('elearning_iabm.training_provider_organization')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    :disabled="notAdmin"
                    v-model="formData.org_id"
                    :options="orgProfileList"
                    id="org_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
              <b-col sm="10">
              <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="training_type_id"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                    plain
                    v-model="formData.training_type_id"
                    :options="trainingTypeList"
                    id="training_type_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
              <b-col sm="10">
              <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="training_title_id"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                    plain
                    v-model="formData.training_title_id"
                    :options="trainingTitleList"
                    id="training_title_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
              <b-col sm="10">
                <ValidationProvider name="Application Deadline" vid="application_deadline" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="application_deadline"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('teaGardenPduService.application_deadline')}} <span class="text-danger">*</span>
                    </template>
                    <date-picker
                      id="application_deadline"
                      class="form-control"
                      v-model="formData.application_deadline"
                      :class="errors[0] ? 'is-invalid' : ''"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :placeholder="$t('globalTrans.select')"
                      :config="dateConfig2"
                    >
                    </date-picker>
                    <div class="invalid-feedback d-block">
                    {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col sm="10">
                <ValidationProvider name="Training Start Date" vid="training_start_date" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="training_start_date"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_iabm.training_start_date')}} <span class="text-danger">*</span>
                    </template>
                    <date-picker
                      id="training_start_date"
                      class="form-control"
                      v-model="formData.training_start_date"
                      :class="errors[0] ? 'is-invalid' : ''"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :placeholder="$t('globalTrans.select')"
                      :config="dateConfig"
                      @input="dateValid($event)"
                    >
                    </date-picker>
                    <div class="invalid-feedback d-block">
                    {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col sm="10">
              <ValidationProvider name="Training End Date" vid="training_end_date" rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="5"
                  label-for="training_end_date"
                  slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                  {{$t('elearning_iabm.training_end_date')}} <span class="text-danger">*</span>
                  </template>
                  <date-picker
                    id="training_end_date"
                    class="form-control"
                    v-model="formData.training_end_date"
                    :class="errors[0] ? 'is-invalid' : ''"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('globalTrans.select')"
                    :config="dateConfig1"
                  >
                  </date-picker>
                  <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
              <b-col sm="10">
              <ValidationProvider name="Description (En)"  vid="description" rules="">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="description"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('globalTrans.description')}} {{ $t('globalTrans.en') }}
                    </template>
                    <b-form-textarea
                        rows="2"
                        id="description"
                        v-model="formData.description"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-textarea>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
              <b-col sm="10">
              <ValidationProvider name="Description (Bn)"  vid="description_bn" rules="">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="description_bn"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('globalTrans.description')}} {{ $t('globalTrans.bn') }}
                    </template>
                    <b-form-textarea
                        rows="2"
                        id="description_bn"
                        v-model="formData.description_bn"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-textarea>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
              <b-col sm="10">
              <ValidationProvider name="Target Audience"  vid="target_audience" rules="">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="target_audience"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('elearning_iabm.target_audience')}} {{ $t('globalTrans.en') }}
                    </template>
                    <b-form-input
                        id="target_audience"
                        v-model="formData.target_audience"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
              <b-col sm="10">
              <ValidationProvider name="Target Audience (Bn)"  vid="target_audience_bn" rules="">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="target_audience_bn"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('elearning_iabm.target_audience')}} {{ $t('globalTrans.bn') }}
                    </template>
                    <b-form-input
                        id="target_audience_bn"
                        v-model="formData.target_audience_bn"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
              <b-col sm="10">
              <ValidationProvider name="Circular type"  vid="circular_type" rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="circular_type"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('ditfTradeFairManages.circular_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.circular_type"
                        :options="circularTypeList"
                        id="circular_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
              <b-col sm="10">
              <ValidationProvider v-if="formData.circular_type === 1" name="Registration Fee" vid="registration_fee" rules="required">
                <b-form-group
                    class="row"
                    label-cols-sm="5"
                    label-for="registration_fee"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('elearning_iabm.registration_fee')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                        id="registration_fee"
                        type="number"
                        v-model="formData.registration_fee"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
            </b-row>
            <br/>
            <b-table-simple bordered>
              <thead>
                <tr class="bg-primary">
                  <th style="width:10%">{{ $t('globalTrans.sl_no') }}</th>
                  <th style="width:35%">{{ $t('globalTrans.document') }} {{ $t('globalTrans.name') }}</th>
                  <th style="width:40%">{{ $t('elearning_iabm.upload_guidelines') }}</th>
                  <th style="width:15%">{{ $t('globalTrans.action') }}</th>
                </tr>
              </thead>
              <b-tbody>
                <b-tr v-for="(item, index) in formData.upload_guidelines" :key="index">
                    <b-td>
                        {{ $n(index + 1) }}
                    </b-td>
                    <b-td>
                      <b-form-input
                        id="attachment_name"
                        :name="'upload_guidelines['+index+'][attachment_name]'"
                        v-model="item.attachment_name"
                      ></b-form-input>
                    </b-td>
                    <b-td>
                      <input type="hidden" :name="'upload_guidelines['+index+'][id]'" v-model="item.id"/>
                      <input type="hidden" :name="'upload_guidelines['+index+'][pre_attachment]'" v-model="item.attachment"/>
                      <b-form-file
                        :name="'upload_guidelines['+index+'][attachment]'"
                        v-model="item.attachment"
                      ></b-form-file>
                      <a v-if="item.id" target = '_blank' :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/' + item.attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                      <span>{{ $i18n.locale == 'en' ? '[Maximum file size is 2 MB and file format is DOC/DOCX/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 2 MB এবং ফাইল ফরমেট DOC/DOCX/PDF]' }}</span>
                    </b-td>
                    <b-td>
                      <span class="d-flex text-center">
                        <b-button v-show="index || ( !index && formData.upload_guidelines.length > 1)" type="button" @click="deleteItem(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                      </span>
                    </b-td>
                </b-tr>
              </b-tbody>
              <tfoot>
                <tr>
                  <td colspan="3"></td>
                  <td><b-button  @click="addItem()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.add')}}</b-button></td>
                </tr>
              </tfoot>
            </b-table-simple>
              <div class="row">
              <div class="col-sm-3"></div>
              <div class="col text-right">
                <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
              </div>
              </div>
          </b-form>
          </ValidationObserver>
      </b-col>
      </b-row>
  </b-overlay>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularPublicationStore, circularMemoList, circularPublicationUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.getOrgList(this.formData.org_type_id)
    } else {
      this.getCircularList()
       this.formData = Object.assign({}, this.formData, {
        // nominated_org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.currentFiscalYearId
      })
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.notAdmin = true
      this.formData.org_id = this.$store.state.Auth.authUser.office_detail.org_id
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      dateConfig: { static: true, minDate: '' },
      dateConfig1: { static: true, minDate: '' },
      dateConfig2: { static: true, minDate: new Date().toISOString().split('T')[0] },
      locationIndex: '',
      notAdmin: false,
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: '',
        fiscal_year_id: 0,
        org_type_id: null,
        nominated_org_id: null,
        org_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        application_deadline: '',
        training_start_date: '',
        training_end_date: '',
        target_audience: '',
        target_audience_bn: '',
        upload_guidelines: [
          {
            id: '',
            attachment_name: null,
            attachment: null,
            pre_attachment: []
          }
        ],
        description: '',
        description_bn: '',
        circular_type: 0,
        registration_fee: '',
        status: 1
      },
      orgList: [],
      circularList: [],
      trainingTitleList: [],
      circularType: [
        {
          value: 1,
          text: 'With Fee',
          text_en: 'With Fee',
          text_bn: 'ফি সহ'
        },
        {
          value: 2,
          text: 'Without Fee',
          text_en: 'Without Fee',
          text_bn: 'ফি ছাড়া'
        }
      ]
    }
  },
  computed: {
    orgProfileList () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    orgTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'এমওসি' : 'MOC',
          text_en: 'MOC',
          text_bn: 'এমওসি'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'ব্যক্তিগত' : 'Private',
          text_en: 'Private',
          text_bn: 'ব্যক্তিগত'
        }
      ]
    },
    circularTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'ফি সহ' : 'With Fee',
          text_en: 'With Fee',
          text_bn: 'ফি সহ'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'ফি ছাড়া' : 'Without Fee',
          text_en: 'Without Fee',
          text_bn: 'ফি ছাড়া'
        }
      ]
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.fiscal_year_id': function (newValue) {
      this.getCircularList()
    },
    // 'formData.org_type_id': function (newValue) {
    //   if (newValue.length) {
    //     this.orgList = this.getOrgList(newValue)
    //   } else {
    //     this.orgList = []
    //   }
    // },
    'formData.training_type_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.formData.fiscal_year_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularMemoList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
         const listObject = result.data
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
        })
        this.circularList = tmpList
      }
      this.circularLoading = false
    },
    dateValid (event) {
      this.dateConfig1.minDate = event
    },
    dateValid2 (event) {
      this.dateConfig.minDate = event
    },
    addItem () {
      let istheare = true
      for (let i = 0; i < this.formData.upload_guidelines.length; i++) {
        if (this.formData.upload_guidelines[i].attachment_name === null && this.formData.upload_guidelines[i].attachment === null) {
          istheare = false
          break
        }
      }
      if (istheare) {
        this.formData.upload_guidelines.push({
          id: '',
          attachment_name: null,
          attachment: null
        })
      }
    },
    deleteItem (index) {
      this.formData.upload_guidelines.splice(index, 1)
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.nominated_org_id === orgId)
      }
      return OfficeTypeList
    },
    getOrgList (orgTypeIds) {
      const orgDataList = []
      if (orgTypeIds.length > 0) {
        orgTypeIds.forEach((item, key) => {
          const orgList = this.$store.state.TrainingElearning.commonObj.nominatedOrgList.filter(item1 => item1.org_type_id === item)
          orgList.forEach((item1, key) => {
            orgDataList.push(item1)
          })
        })
        const searchData = []
        if (orgDataList.length > 0) {
          orgDataList.forEach((item, key) => {
            if (this.formData.nominated_org_id !== null) {
              this.formData.nominated_org_id.map((item1) => {
                if (item1 === item.value) {
                  searchData.push(item1)
                }
              })
            }
          })
        }
        this.formData.nominated_org_id = searchData
      } else {
        this.formData.nominated_org_id = []
      }
      this.orgList = orgDataList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_type_id === categoryId)
      }
      return trainingTitleList
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData(document.getElementById('form'))
      Object.keys(this.formData).map(key => {
        if (key === 'org_type_id') {
          formData.append('org_type_id', JSON.stringify(this.formData.org_type_id))
        } else if (key === 'nominated_org_id') {
          formData.append('nominated_org_id', JSON.stringify(this.formData.nominated_org_id))
        } else if (key !== 'upload_guidelines') {
          formData.append(key, this.formData[key])
        }
      })
      if (this.formData.id) {
        formData.append('_method', 'POST')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${circularPublicationUpdate}/${this.id}`, formData, config)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, circularPublicationStore, formData, config)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
